.button:hover{
    background-color: #f2024e !important;
}
.rdw-editor-wrapper{
    display:flex;
    flex-direction: column-reverse;
}
.public-DraftStyleDefault-block{
    height:70px;
    margin-left: 55px;
}
.DraftEditor-root{
    overflow: hidden;
}