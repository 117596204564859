body {
  background-color: #000;

  overflow-x: hidden;
}

img {
  max-width: 100%;
}

.ag-format-container {
  width: 90%;
  margin: 0 auto;

  position: relative;
}

.ag-timeline-block {
  padding: 30px 0;
}
.ag-timeline_title-box {
  padding: 0 0 30px;

  text-align: center;
}
.ag-timeline_tagline {
  font-size: 40px;
  color: rgb(84, 89, 95);
}
.ag-timeline_title {
  background-image: url(https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/timeline/images/bg.jpg);
  background-repeat: no-repeat;
  background-position: 50% 50%;

  background-size: cover;
  -webkit-background-clip: text;
  background-clip: text;
  text-fill-color: transparent;
  color: transparent;

  font-size: 80px;
}

.ag-timeline_item {
  margin: 0 0 50px;
  position: relative;
}
.ag-timeline_item:nth-child(2n) {
  text-align: right;
  margin-right:2.2em;
}

.ag-timeline {
  display: inline-block;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;

  position: relative;
}
.ag-timeline_line {
  width: 5px;
  /* border: 1px dashed #393935;  */
  background-color: #F4F8FA;

  position: absolute;
  top: 5px;
  left: 50%;
  bottom: 0;

  overflow: hidden;

  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}

.ag-timeline_line-progress {
  width: 100%;
  height: 20%;
  background-color: transparent;
  border: 10px solid #1D5A93; 
}

.ag-timeline-card_box {
  padding: 0 0 0px 50%;
  display:flex;
  /* align-items:flex-start; */
}
.ag-timeline_item:nth-child(2n) .ag-timeline-card_box {
  padding: 0 50% 0px 0;
  float:right;
}
.ag-timeline-card_point-box {
  display: inline-block;
  margin: 0 14px 0 -10px;
}
.ag-timeline_item:nth-child(2n) .ag-timeline-card_point-box {
  margin: 0 -28px 0 14px;
}
.ag-timeline-card_point {
  height: 20px;
  line-height: 20px;
  width: 20px;
  border: 3px solid #1D5A93;
  background-color: #FEFBEB;

  text-align: center;
  font-family: 'ESL Legend', sans-serif;
  font-size: 20px;
  color: #FFF;

  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.js-ag-active .ag-timeline-card_point {
  /* color: #1d1d1b; */
  /* background-color: #FF0; */
}
.ag-timeline-card_meta-box {
  display: inline-block;
  margin-top:-15px;
  width:130px;
  height:45px;
  padding-top:7px;
  padding-left:33px;
  background: url("https://dev.united.hudestudio.com/assets/images/milestonebgs.svg") no-repeat;
}
.ag-timeline-card_meta {
  margin: 0px 0 0;
  text-wrap: nowrap;
  font-family: 'Inter';
  font-size: 1.25em;
  width:65%;
  color: rgb(254, 251, 235);
}
.ag-timeline-card_item {
  display: inline-block;
  width: 50%;
  margin: -6em -1em 50px -10px;
  background-color: transparent;
  text-align: right;

  opacity: 0;

  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;

  -webkit-box-shadow: 0 0 0 0 rgba(0,0,0,.5);
  -moz-box-shadow: 0 0 0 0 rgba(0,0,0,.5);
  -o-box-shadow: 0 0 0 0 rgba(0,0,0,.5);
  box-shadow: 0 0 0 0 rgba(0,0,0,.5);

  -webkit-transition: -webkit-transform .5s, opacity .5s;
  -moz-transition: -moz-transform .5s, opacity .5s;
  -o-transition: -o-transform .5s, opacity .5s;
  transition: transform .5s, opacity .5s;

  position: relative;
}
.ag-timeline_item:nth-child(2n+1) .ag-timeline-card_item {
  -webkit-transform: translateX(-30%);
  -moz-transform: translateX(-30%);
  -ms-transform: translateX(-30%);
  -o-transform: translateX(-30%);
  transform: translateX(-30%);
}
.ag-timeline_item:nth-child(2n) .ag-timeline-card_item {
  text-align:left;
  -webkit-transform: translateX(30%);
  -moz-transform: translateX(30%);
  -ms-transform: translateX(30%);
  -o-transform: translateX(30%);
  transform: translateX(30%);
}
.js-ag-active.ag-timeline_item:nth-child(2n+1) .ag-timeline-card_item {
  opacity: 1;

  -webkit-transform: translateX(0%);
  -moz-transform: translateX(0%);
  -ms-transform: translateX(0%);
  -o-transform: translateX(0%);
  transform: translateX(0%);
}
.js-ag-active.ag-timeline_item:nth-child(2n) .ag-timeline-card_item {
  opacity: 1;

  -webkit-transform: translateX(0%);
  -moz-transform: translateX(0%);
  -ms-transform: translateX(0%);
  -o-transform: translateX(0%);
  transform: translateX(0%);
}
.ag-timeline-card_arrow {
  height: 18px;
  width: 18px;
  margin-top: 20px;
  background-color: #282828;

  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;

  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.ag-timeline_item:nth-child(2n+1) .ag-timeline-card_arrow {
  margin-left: calc(-18px / 2);
  margin-right: calc(-18px / 2);
}
.ag-timeline_item:nth-child(2n) .ag-timeline-card_arrow {
  margin-left: -10px;

  right: auto;
  left: 0;
}
.ag-timeline-card_img {
  width: 100%;
}
.ag-timeline-card_info {
  padding: 20px 30px;
}
.ag-timeline-card_title {
  display: block;
  margin: 0px 0 10px;

  font-family: 'Inter';
  font-weight: bold;
  font-size: 1.5em;
  color: inherit;
}
.ag-timeline-card_desc {
  line-height: 1.45;

  font-size: 1em;
  font-weight: 400;
  width:100%;
  float:right;
  /* color: #FFF; */
}
.ag-timeline_item:nth-child(2n) .ag-timeline-card_desc {
  float:left;
}


@media only screen and (max-width: 979px) {
  .ag-timeline_line {
    left: 30px;
  }

  .ag-timeline_item:nth-child(2n) {
    text-align: left;
  }

  .ag-timeline-card_box,
  .ag-timeline_item:nth-child(2n) .ag-timeline-card_box {
    padding: 0 0 20px;
    float:left;
  }
  .ag-timeline_item:nth-child(2n) .ag-timeline-card_box {
    flex-flow: row-reverse;
  }
  .ag-timeline-card_meta-box {
    /* display: none; */
    margin-left: 10px;
  }
  .ag-timeline-card_point-box,
  .ag-timeline_item:nth-child(2n) .ag-timeline-card_point-box {
    margin: 0 0 0 20px;
  }
  .ag-timeline-card_point {
    height: 20px;
    line-height: 20px;
    width: 20px;
  }
  .ag-timeline-card_item {
    width: auto;
    margin: -65px 0 0 45px;
    text-align:left;
  }
  .ag-timeline_item:nth-child(2n+1) .ag-timeline-card_item,
  .ag-timeline_item:nth-child(2n) .ag-timeline-card_item {
    -webkit-transform: translateX(30%);
    -moz-transform: translateX(30%);
    -ms-transform: translateX(30%);
    -o-transform: translateX(30%);
    transform: translateX(30%);
  }
  .ag-timeline_item:nth-child(2n+1) .ag-timeline-card_arrow {
    right: auto;
    left: 0;
  }
  .ag-timeline-card_title {
    display: block;
    margin-top:40px;
    font-size:1.25em;
  }
  .ag-timeline-card_arrow {
    margin-top: 12px;
  }
}

@media only screen and (max-width: 767px) {
  .ag-format-container {
    width: 96%;
  }

  .ag-timeline-card_img {
    height: auto;
    width: auto;
  }
}

@media only screen and (max-width: 639px) {
  .ag-timeline_title {
    font-size: 60px;
  }

  .ag-timeline-card_info {
    padding: 10px 15px;
  }
  .ag-timeline-card_desc {
    font-size: 14px;
  }
}

@media only screen and (max-width: 479px) {

}

@media (min-width: 768px) and (max-width: 979px) {
  .ag-format-container {
    width: 750px;
  }

}

@media (min-width: 980px) and (max-width: 1161px) {
  .ag-format-container {
    width: 960px;
  }

}