#project-slider .slick-list, #project-slider .slick-list img {
    height:360px;
}

@media (max-width: 500px) {
    #project-slider .slick-list, #project-slider .slick-list img {
        height:180px;
    }   
}

#project-slider .slick-prev {
    background: rgba(255 255, 255, 0.8);
    padding: 20px;
    left:0;
    color: #4F9A41;
    z-index:1;
}
#project-slider .slick-next {
    background: rgba(255 255, 255, 0.8);
    padding: 20px;
    right:0;
    color: #4F9A41;
    z-index:1;
}
#project-slider .slick-prev:before, #project-slider .slick-next:before {
    color: #4F9A41;
    font-family:'Font Awesome 5 Free';
    line-height:0.25;
    margin-left: -10px;

}