body {
    --wp--preset--color--contrast: #000000;
}
.wp-block-gallery.wp-block-gallery-2 {
    --wp--style--unstable-gallery-gap : 0.5em;
}
figure.wp-block-gallery.has-nested-images {
    align-items: normal;
}
:where(body .is-layout-constrained) > :last-child:last-child {
    margin-block-end: 0;
}
body .is-layout-flex {
    display: flex;
}
body .is-layout-constrained > :where(:not(.alignleft):not(.alignright):not(.alignfull)) {
    margin-left: auto !important;
    margin-right: auto !important;
}

.wp-block-gallery.has-nested-images figure.wp-block-image {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    max-width: 100%;
    position: relative;
}
.wp-block-gallery.has-nested-images.columns-default figure.wp-block-image:not(#individual-image):first-child:nth-last-child(2), .wp-block-gallery.has-nested-images.columns-default figure.wp-block-image:not(#individual-image):first-child:nth-last-child(2)~figure.wp-block-image:not(#individual-image) {
    width: calc(50% - 0.5em *.5);
}
.wp-block-gallery.has-nested-images.is-cropped figure.wp-block-image:not(#individual-image) {
    align-self: inherit;
}

.wp-block-image img {
    verticle-align: bottom;
    box-sizing: border-box;
    height: fit-content;
}
.blogpage h1 {
    font-family: Playfair Display;   
    font-size:2.5em !important;
}

.wp-lightbox-container img {
    cursor:zoom-in;
}
.wp-block-gallery.has-nested-images figure.wp-block-image img {
    display:block;
    max-width: 100% !important;
}

.wp-block-gallery.has-nested-images.is-cropped figure.wp-block-image:not(#individual-image) a, .wp-block-gallery.has-nested-images.is-cropped figure.wp-block-image:not(#individual-image) img {
flex: 1 0 0%;
height: 100%;
object-fit: cover;
width: 100%;    
}
.wp-lightbox-container button:focus, .wp-lightbox-container button:hover, .wp-lightbox-container button:not(:hover):not(:active):not(.has-background) {
    background-color: rgba(90,90,90,.25);
    border: none;
}
.wp-lightbox-container button {
    align-items: center;
    backdrop-filter: blur(16px) saturate(180%);
    padding: 0;
    border-radius: 4px;
    cursor: zoom-in;
    display:flex;
    justify-content:center;
    position: absolute;
    opacity: 0;
    text-align:center;
    transition: opacity .2s ease;
    width: 20px;
    height: 20px;
    z-index: 100;
    right: 16px;
    top: 16px;
}
.wp-lightbox-overlay {
    box-sizing:border-box;
    cursor: zoom-out;
    height: 100vh;
    left: 0;
    overflow:hidden;
    position: fixed;
    top:0;
    visibility: hidden;
    width: 100vw;
    z-index: 100000;
}
.wp-lightbox-overlay.zoom.hideanimationenabled:not(.active) {
    animation:none;
}
.wp-lightbox-overlay button {
    background: none;
    border: none;
}
.wp-lightbox-overlay .close-button {
    align-items:center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    min-height:40px;
    min-width:40px;
    padding: 0;
    position: absolute;
    right: 16px;
    left: 16px;
    z-index: 5000000;
}
.wp-lightbox-overlay .close-button:focus, .wp-lightbox-overlay .close-button:hover, .wp-lightbox-overlay .close-button:not(:hover):not(:active):not(.has-background) {
    background:none;
    border: none;
    fill: #000000;
}
.wp-lightbox-overlay.zoom.hideanimationenabled:not(.active) .lightbox-image-container {
    animation: lightbox-zoom-out .4s;
}
.wp-lightbox-overlay .lightbox-image-container {
    height: 466px;
    left: 50%;
    overflow: hidden;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transform-origin: top left;
    width: 700px;
    z-index: 9999999999;
}
.wp-lightbox-overlay .wp-block-image {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    height: 100%;
    justify-content: center;
    margin: 0 ;
    position: relative;
    transform-origin: 0 0;
    width: 100%;
    z-index: 3000000;
}
.wp-lightbox-overlay.zoom.hideanimationenabled:not(.active) .scrim {
    animation: turn-off-visibility .4s forwards;
}
.wp-lightbox-overlay .scrim {
    background-color: #FFF;
    height: 100%;
    opacity: 0.9;
    position: absolute;
    width: 100%;
    z-index: 2000000;
    
}

.linkBUtton{
    line-height:1.5;
    text-transform: capitalize;
    font-size: 1.3em;
    font-family: Inter;
    padding: 0px;
    color: rgba(0, 0, 0, 0.77);
    margin-bottom:5px;
    text-decoration: underline;
    transition:none;
}
.linkBUtton:hover{
    text-decoration: none;
    box-shadow: none;
    background-color:unset;
    color: rgba(0, 0, 0, 1);
}
.post-form {
    display: flex;
    gap:20px;
    width: 100%;
}
.post-div{
    width: 50%;
}
@media (max-width: 767px){
    .post-form {
        flex-flow: column;
    }   
    .post-div{
        width: 100%;
    }
}