.numbers {
    font-size: inherit;
    line-height: 1;
    text-align: inherit;
    margin: 10px auto;
    overflow: hidden;
  }
  .numbers__window {
    display: inline-block;
    overflow: hidden;
    width: 0.7em;
    height: 1em;
  }
  .numbers__window__digit {
    font: inherit;
    word-break: break-all;
    display: block;
    width: 0;
    padding: 0 0.68em 0 0;
    margin: 0 auto;
    overflow: inherit;
    animation: counting 0.4s steps(10) forwards infinite;
  }
  .numbers__window__digit::before {
    content: attr(data-fake);
    display: inline-block;
    width: 100%;
    height: auto;
  }
  .numbers__window__digit--1 {
    animation-iteration-count: 3;
  }
  .numbers__window__digit--2 {
    animation-iteration-count: 6;
  }
  .numbers__window__digit--3 {
    animation-iteration-count: 9;
  }
  .numbers__window__digit--4 {
    animation-iteration-count: 12;
  }
  .numbers__window__digit--5 {
    animation-iteration-count: 15;
  }
  
  @keyframes counting {
    100% {
      transform: translate3d(0, -10em, 0);
    }
  }