@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;400;500&family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500&family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;500&family=Roboto&display=swap');
@import "animate.css/animate.min.css";

@font-face {
  font-family: 'Playfair Display';
  font-size:16px;
}

@font-face {
  font-family:'Inter';
  font-size:16px;
  font-weight: 400;
}
@font-face {
  font-family:'Manrope';
  font-size:16px;
  font-weight: 400;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

h1 {
  font-size:56px !important;
}


.link-color {
  color: #4F9A41;
}

.link-color:hover, .link-color:active {
  color: #333333;
}

.size-20 {
  font-size:20px;
}
.normal-text {
  font-family: 'Inter';
  font-size: 16px;
  line-height:32px;
  font-weight:400;
  color:#27333E;
}

.slider-title-text {
  font-family: 'Manrope';
  font-size: 16px;
  line-height:150%;
  font-weight:500;
  color:#27333E;
}
.text-color {
  color: #27333E;
}
.footer-text-color {
  color:#535353;
}
.title-color {
  color: #1D5A93;
}

.App {
  /* padding-left:80px; */
  /* padding-right:80px; */
  text-align: left;
}
a:-webkit-any-link:focus-visible {
  outline-offset: 0px;
}
.home-banner{
  width:100%;
  max-width:1440px;
  height:600px; 
  display:flex; 
  flex-direction:column;
  align-items:flex-start; 
  justify-content:center;
  padding-left: 11em;
}
.icon{
  align-items: center;
  display:inlie-flex;
  justify-content: center;
  position:relative;
  width:24px;
  margin: 6px 10px 0 0;
  height: 2px;
  border-top:2px solid;
  transition: transform .3s;
}
.menu-icon {
  content:'';
  display:block;
  top:3px;
  right: 0;
  width: 60%;
  height: 3px;
  border-top:2px solid;
  position: absolute;
  transition: transform .3s;
}
.iconOpened {
  transform:rotate(45deg);
  margin-top:8px;
  width:18px;
}
.menu-iconOpened {
  transform:rotate(-90deg);
  margin-top:-5px;
  width:18px;
}
.img-wrapper {
  position:absolute;
  height:100%;
  width:100%;
  overflow:hidden;
  top:0;
  padding-top:0;
}
.img-wrapper img{
  transform:scale(1);
  transition: all .3s;
}
.img-wrapper:hover img {
  transform:scale(1.03);
}

.btn img{
 margin-left: 5px;
 transition: all .3s;
}
.btn:hover {
  background-color: transparent;
}
.btn:hover img {
  margin-left:10px;
  transition: all .3s;
}

.enrich-div {
  width:100%;
  max-width:1440px;
  display:flex; 
  justify-content:center;
  padding-top:6em;
  padding-bottom:6em;
}

.titleStyle {
  font-size:2em;
  font-family:'Playfair Display';
  font-weight:500;
  line-height:2;
}
.subtitleStyle {
  font-size:1.5em;
  line-height: 1.5em !important;
}
.quote-owner, .quote-title {
  line-height: 1.7 !important;
  font-size: 1.5em !important;
  font-family: Inter !important;
}
.customer-flex {
  flex-direction: row;
}

.header-div {
  padding:1.2em 2em;
}
.header1-div {
  display:flex;
  justify-content:space-between;
  margin-lLeft:2em;
  margin-right:3em;
  align-items:center;
}
.footer-div{
  padding-top:96px;
  padding-bottom:0;
  width:100%;
  padding-left:100px;
  padding-right:100px;
}
.footer1-div {
  display:flex;
  width:100%; 
  flex-wWrap: nowrap; 
  padding-bottom:80px;
  flex-direction:row;
}
.enrich-img {
  width:100%;
  max-width:764px;
  height:fit-content;
  max-height:362px;
}
.footprint{
  padding-left:80px;
  margin-left:80px;
}
.footprint-div {
  padding: 100px 96px;
  display:flex;
}
.scroll-btn {
  margin-left:50px;
  margin-top:40px;
}
.scroll-btn .txt {
  margin-left: 24px;
  margin-right: 24px;
}
.leading-div {
  display:flex;
}
.leading1-div {
  padding-left:160px;
  padding-right:0; 
}

.leading-title{
  margin-left:100px;
  margin-right:16px; 
  height:237px;
  padding-top:1.7em;
  padding-bottom:1.7em;
}
.leading-subtitle {
  font-size: 2em !important; 
  font-weight: 700 !important
}
.client-div {
  padding-left:180px;
}
.client-title {
  text-align:left;
}

.getknow1-div {
  padding-left:80px;
  padding-right:80px; 
  padding-top:96px;
}
.getknow-div {
  padding-left:80px;
}
.getknow-section {
  padding-left:80px;
  padding-right:44px;
  padding-bottom:76px;
}
.getknow1-section {
  display:flex;
  flex-flow:row;
  padding-right:36px;
  padding-bottom:24px;
  column-gap:24px;
}
.what-style {
  width:50%;
  margin-top:-6.2em; 
  margin-right:-1.3em; 
  padding-left:40px;
  padding-right:72px;
  padding-bottom:100px;
}
.what1-style {
  height:480px;
  max-height:480px
}
.what-text {
  font-size:1.5em !important;
   font-weight:500 !important;
}

.contact-details {
    padding-left:0px;
    padding-right:0px; 
    display:flex;
    width:100%;
    max-width:1440px;
    justify-content:center;
}
.page-banner-image {
  width:fit-content;
}
.breadcrumb-div{
  padding-left:100px;
  padding-right:100px;
}
.title-div{
  padding-left:80px;
  padding-right:80px;
  width:100%;
  max-width:1440px;
  display:flex;
  justify-content:center;
  padding-top:2em
}
.optionStyle {
  width:170px;;
  height:40px;
  font-size: 1em;
  color:#4F9A41;
  background-color:transparent;
  border: unset;
  padding: 0px 10px;
}
.optionSpan {
  color: #B2C7D7;
  margin-left: 1em;
  margin-right: 1em;
  font-size:24px;
  line-height:1;
  padding-top: 5px;
}
.vertical-div{
  width: 100%;
  max-width:1440px;
  padding-left: 180px;
  padding-right: 180px;
  display: flex;
  align-items:center;
  flex-direction: row;
}
.select1-div {
  padding:2em 180px 5em;
  display:flex;
  width:100%;
  max-width:1440px;
  justify-content:center;
}
.select-div {
  display:flex;
  justify-content:flex-start;
  column-gap:1.53em;
}
.infocus-div {
  padding:5em 80px;
  margin-bottom:5em;
  width:100%;
  max-width:1440px
}
.infocus1-div {
  padding-left:80px;
  padding-right:80px;
}
.infocus-flex {
  display: flex;
  flex-direction: row;
  column-gap: 2em;
}
.customer-div {
  padding:5em 180px;
  width: 100%; 
  max-width:1440px;
}

.multitude-div {
  width: 100%; 
  background-color: #D8F2FF;
  padding: 5em 0px;
  display:flex;
    justify-content:center;
}
.multitude1-div {
  width: 100%;
  max-width:1440px;
  padding:0px 180px;
}
.multitude-subdiv {
  /* margin-left:20px; */
  /* margin-right:48px; */
}
.product-div {
  width:100%;
  max-width:1440px;
  padding :1.5em 80px;
}
.product1-div {
  padding: 0  80px;
}
.product-subdiv {
  width:100%;
  display:flex;
  column-gap: 2em;
  padding-top: 4em;
  padding-bottom:3em;
  justify-content:space-between;
}
.product1-subdiv {
  margin-right: 3em;
}
.project-div {
  width:100%;
  background-color:#D8F2FF;
  padding:7em 80px;
  display:flex;
  justify-content:center;
}
.project1-div {
  width:100%;
  max-width:1440px;
  padding: 0 100px;
  display:flex;
  justify-content:center;
  flex-flow:row;
}
.multiface-div {
  padding:0 80px; 
  width: 100%;
  max-width:1440px;
}
.multiface1-div {
  width: 100%;
  padding: 4em 80px;
}
.expertise-div {
  width: 100%;
  display:flex;
  column-gap: 2em;
  padding: 1em 0px;
  justify-content: space-between;
  flex-flow:row;
}
.client-textimonial-div {
  padding: 0px 80px;
  max-width:1440px;
}
.client-textimonial1-div {
  width:100%; 
  padding: 6em 80px;
}
.client-textimonial-subdiv {
  padding: 3em 100px;
  background-color:#DAFAD8;
  border-radius:4px
}
.aboutus-get-subdiv {
  display:flex;
  padding-right:36px;
  padding-bottom: 5em;
  column-gap:24px;
  flex-flow:row;
}

@media (min-width: 1536px){
  .contact-details {
    padding-left:80px !important;
    padding-right:80px !important;
  }
}
@media screen and (min-width : 767px) and (max-width : 1200px){
  .normal-text {
    font-size: 14px;
  }
  .breadcrumb-div{
    padding-left:20px;
    padding-right:20px;
  }
  .vertical-div{
    padding-left:100px;
    padding-right:100px;
  }
  .select1-div {
    padding:2em 100px 5em; 
  }
  .infocus1-div {
    padding-left:20px;
    padding-right:20px;
  }
  .customer-div {
    padding:5em 100px;
  }
  .client-div {
    padding-left:100px;
  }
  .subtitleStyle {
    font-size: 1.25em;
  }
}


@media (max-width: 767px) {

  .contact-details {
    padding-left:10px !important;
    padding-right:10px !important;
    flex-flow:column-reverse;
  }
  .normal-text {
    /* line-height: 1.5em; */
  }
  .page-banner-image {
    width:80%;
  }
  .breadcrumb-div{
    padding-left:0px;
    padding-right:0px;
  }
  .title-div{
    padding-left:20px;
    padding-right:20px;
  }

  .optionStyle {
    width:100%;
    padding: 0px 5px;
  }
  .optionSpan {
    margin-left: 0.25em;
    margin-right: 0.15em;
  }
  
  .vertical-div{
    flex-direction: column;
    align-items:flex-start;
    padding-top: 1em;
    padding-left: 10px;
    padding-right: 10px;
  }
  .select1-div {
    padding:2em 20px 5em;
    
  }
  .select-div {
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    column-gap:unset;
  }
  .infocus-div {
    padding:3em 20px;
    margin-bottom:0;
  }
  .infocus1-div {
    padding-left:0px;
    padding-right:0px;
  }
  .infocus-flex {
    flex-direction: column;
  }
  .customer-div {
    padding: 5em 20px;
  }
  .multitude-div {
    width: 100%; 
    background-color: #D8F2FF;
    padding: 3em 20px 3em;
    
  }
  .multitude1-div {
    padding: 0;
  }
  .multitude-subdiv {
    margin: 0;
  }

  .product-div {
    padding :1em 20px;
  }
  .product1-div {
    padding: 0px;
  }
  .product-subdiv {
    column-gap: 0em;
    padding-top: 1em;
    padding-bottom: 1em;
    flex-direction: column;
    /* justify-content:space-between; */
  }
  .product1-subdiv {
    margin-right: 0;
  }

  .project-div {
    padding:5em 20px;
  }
  .project1-div {
    flex-flow:column;
  }
  .multiface-div {
    padding:0 20px; 
  }
  .multiface1-div {
    padding: 4em 0px 3em;
  }

  .expertise-column {
    flex-flow:column;
  }
  .expertise-column-reverse {
    flex-flow:column-reverse;
  }
  .client-textimonial-div {
    padding: 0px 20px;
  }
  .client-textimonial1-div {
    padding: 3em 10px 6em;
  }
  .client-textimonial-subdiv {
    padding: 3em 20px;
  }
  .aboutus-get-subdiv {
    flex-flow:column;
    column-gap:0;
    padding-bottom: 3em;
    padding-right: 0;
  }
  .timeLine::before {
    flex:0;
    padding: 0 5px;
  }


h1 {
  font-size:28px !important;
}
.home-banner{
  padding-left: 0;
  margin-left: 1em;
}
.header-div {
  padding:1.2em 1em;
}
.header1-div {
  margin-left:0;
  margin-right:0;
}
.footer-div{
  padding-top: 3em;
  padding-bottom:0;
  width:100%;
  padding-left:20px;
  padding-right:20px;
}
.footer1-div {
  flex-direction:column;
}

.enrich-div {
  width:auto;
  max-width:100%;
  display:flex;
  flex-flow:column; 
  justify-content:center;
  margin-left:20px;
  margin-right:20px;
  padding-top: 3em;
  padding-bottom: 3em;
}

.titleStyle {
  font-size:1.5em;
  line-height:1.5;
}
.subtitleStyle {
  font-size:1.25em;
}
.quote-owner, .quote-title {
  font-size: 1.25em !important;
}
.customer-flex {
  flex-direction: column-reverse;
}


.enrich-img {
  width:auto;
  max-width:100%;
  height:fit-content;
  max-height:362px;
  margin-top:1em;
  margin-left:-15px;
  margin-right: -15px;
}
.footprint{
  padding-left:0;
  margin-left: 15px;
}
.footprint-div {
  padding: 50px 1.25em;
  flex-flow:column;
}
.scroll-btn {
  margin-left:0px;
  margin-top:20px;
}
.scroll-btn .txt {
  margin-left: 12px;
  margin-right: 12px;
}
.leading-div {
  flex-flow:column;
}
.leading1-div {
  padding-left:10px;
  padding-right:0; 
}
.leading-title {
  margin-left:10px;
  margin-right:5px; 
  height:100px;
  padding-top:1.7em;
  padding-bottom:1.7em;
}
.leading-subtitle {
  font-size:1.5em !important;
}
.client-div {
  padding-left:1em;
  padding-right:1em;
}
.client-title {
  width:100%;
  text-align:center;
}
.getknow1-div {
  padding-left:20px;
  padding-right:20px; 
  padding-top:4em;
}
.getknow-div {
  padding-left:0;
}
.getknow-section {
  padding-left:0px;
  padding-right:0px;
  padding-bottom:44px;
}
.getknow1-section {
  flex-flow:column;
  padding-right:0px;
  padding-bottom:0px;
  row-gap:16px;
}
.what-style {
  width:95%;
  margin-top:-3.1em; 
  margin-right:0; 
  padding-left:24px;
  padding-right:24px;
  padding-bottom:60px;
}
.what1-style {
  height:260px;
  max-height:260px
}
.what-text{
  font-size: 1em !important;
}
}
@media ((max-width: 500px)){
  .getknow-section {
    padding-bottom:10px;
  }
  .scroll-btn {
    margin-left:0px;
    margin-top:10px;
  }
  .leading-title {
    padding-top: 1em;
    padding-bottom: 1em;
  }
  .client-title {
    margin-left: 15px;
    text-align: left;
  }
}