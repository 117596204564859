.slick-dots {
    text-align: left;
}
.slick-dots li button:before {
    color: #A2C6A0;
    opacity: 1;
    font-size: 13px;
}

.slick-dots li.slick-active button:before {
    color: #4F9A41;
    opacity: 1;
}
.slick-dots li:first-child {
    margin-left: 0;
}
